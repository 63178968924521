import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
export var validateBusinessId = function validateBusinessId(businessId) {
  var regex = /^[\\d]{7}-[\\d]$/;
  if (regex.test(businessId)) {
    return false;
  }
  var multipliers = [7, 9, 10, 5, 8, 4, 2];
  var allNumbersStrip = businessId.replace('-', '').split('');
  var allNumbersInt = allNumbersStrip.map(Number);
  var checkSum = allNumbersInt.pop();
  var total = allNumbersInt.map(function (numberToMultiply, index) {
    return numberToMultiply * multipliers[index];
  }).reduce(function (a, b) {
    return a + b;
  });
  var remainder = total % 11;
  if (remainder === 1) {
    return false;
  }
  if (remainder === 0) {
    return checkSum === remainder;
  }
  return checkSum === 11 - remainder;
};