/**
 * Global identifiers
 */
export var ID;
(function (ID) {
  ID["FEATURE"] = "oneaccount-bonboarding-front";
})(ID || (ID = {}));
export var Countries = [{
  abbrev: 'FI',
  name: 'Finland',
  postal: '^[0-9]{5}$'
}, {
  abbrev: 'SE',
  name: 'Sweden',
  postal: '^[0-9]{3} [0-9]{2}$'
}, {
  abbrev: 'PL',
  name: 'Poland',
  postal: '^[0-9]{2}-[0-9]{3}$'
}, {
  abbrev: 'EE',
  name: 'Estonia',
  postal: '^[0-9]{5}$'
}, {
  abbrev: 'GB',
  name: 'United Kingdom',
  postal: '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))s?[0-9][A-Za-z]{2})$'
}, {
  abbrev: 'CZ',
  name: 'Czech Republic',
  postal: '^[0-9]{3} [0-9]{2}$'
}, {
  abbrev: 'HU',
  name: 'Hungary',
  postal: '^[0-9]{4}$'
}, {
  abbrev: 'DK',
  name: 'Denmark',
  postal: '^[0-9]{5}$'
}, {
  abbrev: 'DE',
  name: 'Germany',
  postal: '^[0-9]{5}$'
}, {
  abbrev: 'ES',
  name: 'Spain',
  postal: '^[0-9]{5}$'
}, {
  abbrev: 'BE',
  name: 'Belgium',
  postal: '^[0-9]{4}$'
}, {
  abbrev: 'NO',
  name: 'Norway',
  postal: '^[0-9]{4}$'
}, {
  abbrev: 'LT',
  name: 'Lithuania',
  postal: '^LT-[0-9]{5}$'
}, {
  abbrev: 'CH',
  name: 'Switzerland',
  postal: '^[0-9]{4}$'
}, {
  abbrev: 'IE',
  name: 'Ireland, Republic of',
  postal: '^(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$'
}, {
  abbrev: 'NL',
  name: 'Netherlands',
  postal: '^(?:NL-)?(d{4})s*([A-Z]{2})$'
}, {
  abbrev: 'SK',
  name: 'Slovakia',
  postal: '^[0-9]{3} [0-9]{2}$'
}, {
  abbrev: 'FR',
  name: 'France',
  postal: '^[0-9]{5}$'
}, {
  abbrev: 'RO',
  name: 'Romania',
  postal: '^[0-9]{6}$'
}, {
  abbrev: 'LV',
  name: 'Latvia',
  postal: '^LV-[0-9]{4}$'
}, {
  abbrev: 'BG',
  name: 'Bulgaria',
  postal: '^[0-9]{4}$'
}, {
  abbrev: 'PT',
  name: 'Portugal',
  postal: '^[0-9]{4}-[0-9]{3}$'
}, {
  abbrev: 'GI',
  name: 'Gibraltar',
  postal: '^GX11 1AA$'
}, {
  abbrev: 'RU',
  name: 'Russian Federation',
  postal: '^[0-9]{6}$'
}, {
  abbrev: 'AT',
  name: 'Austria',
  postal: '^[0-9]{4}$'
}, {
  abbrev: 'IT',
  name: 'Italy',
  postal: '^[0-9]{5}$'
}, {
  abbrev: 'RS',
  name: 'Serbia',
  postal: '^[0-9]{5}$'
}];
export var WizardSteps;
(function (WizardSteps) {
  WizardSteps[WizardSteps["BECOME_CUSTOMER"] = 0] = "BECOME_CUSTOMER";
  WizardSteps[WizardSteps["VERIFY_INFORMATION"] = 1] = "VERIFY_INFORMATION";
  WizardSteps[WizardSteps["PRICING"] = 2] = "PRICING";
  WizardSteps[WizardSteps["CONTRACT"] = 3] = "CONTRACT";
  WizardSteps[WizardSteps["EMAIL_VERIFICATION"] = 4] = "EMAIL_VERIFICATION";
})(WizardSteps || (WizardSteps = {}));
export var termsOfUseHref = {
  fi: 'https://www.posti.fi/mzj3zpe8qb7p/1MFoBwbZm5pWI1x2XLf5yE/e98e5cce6ee411ba60be9c97d0db34c9/posti-sopimusasiakkaiden-sahkoisten-kanavien-kayttoehdot-20230101.pdf',
  sv: 'https://www.posti.fi/mzj3zpe8qb7p/1MFoBwbZm5pWI1x2XLf5yE/6b0ebef337a8d65464830f528874095b/posti-anvandnings-villkor-for-elektroniska-tjanster-for-avtalskunder-20230101.pdf',
  en: 'https://www.posti.fi/mzj3zpe8qb7p/1MFoBwbZm5pWI1x2XLf5yE/5e1397f49fa32756edf8b2ea0d0184de/posti-terms-of-use-of-electronic-channels-for-contract-customers-20230101.pdf'
};
export var HTTP_CODES = {
  OK: 200,
  UNAUTHORIZED: 403,
  NOT_ACCEPTABLE: 406,
  CONFLICT: 409,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504
};
export var FATAL_ERROR_CODES = [HTTP_CODES.INTERNAL_SERVER_ERROR, HTTP_CODES.BAD_GATEWAY, HTTP_CODES.GATEWAY_TIMEOUT];
export var CREDIT_CHECK_FAILED_CODE = HTTP_CODES.NOT_ACCEPTABLE;
export var COMPANY_ALREADY_EXISTS_CODE = HTTP_CODES.CONFLICT;
export var AppErrorCodes;
(function (AppErrorCodes) {
  AppErrorCodes[AppErrorCodes["FATAL_ERROR"] = 0] = "FATAL_ERROR";
  AppErrorCodes[AppErrorCodes["TEMPORARY_MAINTENANCE"] = 1] = "TEMPORARY_MAINTENANCE";
  AppErrorCodes[AppErrorCodes["CREDIT_CHECK_FAILED"] = 2] = "CREDIT_CHECK_FAILED";
  AppErrorCodes[AppErrorCodes["COMPANY_ALREADY_EXISTS"] = 3] = "COMPANY_ALREADY_EXISTS";
  AppErrorCodes[AppErrorCodes["OTHER_ONBOARDING_ERROR"] = 4] = "OTHER_ONBOARDING_ERROR";
  AppErrorCodes[AppErrorCodes["LOGISTICS_CONTRACT_EXISTS"] = 5] = "LOGISTICS_CONTRACT_EXISTS";
})(AppErrorCodes || (AppErrorCodes = {}));
export var LOGOUT_VISIBLE_FOR = [AppErrorCodes.COMPANY_ALREADY_EXISTS, AppErrorCodes.CREDIT_CHECK_FAILED, AppErrorCodes.OTHER_ONBOARDING_ERROR, AppErrorCodes.LOGISTICS_CONTRACT_EXISTS];
export var OnboardingTrackToCodeMap = {
  default: '2114e0c6d1e7f884',
  ecommerce: 'ace46ede1877b095'
};
export var EcomPricingTier;
(function (EcomPricingTier) {
  EcomPricingTier[EcomPricingTier["TIER_1"] = 0] = "TIER_1";
  EcomPricingTier[EcomPricingTier["TIER_2"] = 1] = "TIER_2";
})(EcomPricingTier || (EcomPricingTier = {}));