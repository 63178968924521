import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _typeof from "@babel/runtime/helpers/typeof";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createCustomEvent, numberFormat } from './helpers';

/**
 * Validates does phone number have a valid format
 */
export var validPhoneNumber = function validPhoneNumber(number) {
  var amountOfDigits = number.replace(/[^0-9]/g, '').length;
  if (amountOfDigits > 15) {
    return false;
  }
  var regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
  return regex.test(number);
};
export var isValidReferenceNumber = function isValidReferenceNumber(value) {
  var parts = value.replace(/[\s]*/g, '').split('');
  var checkNumber = Number(parts.pop());
  var sum = parts.reduce(function (total, number, i) {
    if (i + 1 === 2 || i + 1 === 5) {
      return total + parseInt(number) * 3;
    } else if (i + 1 === 3 || i + 1 === 6) {
      return total + parseInt(number) * 7;
    }
    return total + parseInt(number);
  }, 0);
  var ceiledSum = Math.ceil(sum / 10) * 10;
  var subtraction = ceiledSum - sum;
  return subtraction === 10 ? checkNumber === 0 : subtraction === checkNumber;
};
export var isValidIban = function isValidIban(value) {
  value = value.replace(/[\s]*/g, '');
  var letterMap = {
    A: 10,
    B: 11,
    C: 12,
    D: 13,
    E: 14,
    F: 15,
    G: 16,
    H: 17,
    I: 18,
    J: 19,
    K: 20,
    L: 21,
    M: 22,
    N: 23,
    O: 24,
    P: 25,
    Q: 26,
    R: 27,
    S: 28,
    T: 29,
    U: 30,
    V: 31,
    W: 32,
    X: 33,
    Y: 34,
    Z: 35
  };
  var ibanToCheck = (value.substring(4) + value.substring(0, 4)).split('').map(function (char) {
    if (Number(char).toString() === 'NaN') {
      return letterMap[char.toUpperCase()];
    }
    return char;
  }).join('');
  return modulo(ibanToCheck, 97) === 1;
};
export var modulo = function modulo(number, devider) {
  var tmp = '';
  var r = 0;
  for (var i = 0; i < number.length; i++) {
    tmp += number.charAt(i);
    r = Number(tmp) % devider;
    tmp = r.toString();
  }
  return Number(tmp) / 1;
};
var checkHtml5Validation = function checkHtml5Validation(t, validationName, inputRef, rules) {
  if (!inputRef || !inputRef.current) {
    return [true, null];
  }
  var el = inputRef.current;
  var isValidField = el.checkValidity();
  var errorPrefix = validationName ? "errors.".concat(validationName) : "errors";
  if (!isValidField) {
    for (var validityReq in el.validity) {
      if (el.validity[validityReq] === true) {
        var length = void 0;
        switch (validityReq) {
          case 'tooShort':
            length = numberFormat(rules.minLength);
            break;
          case 'tooLong':
            length = numberFormat(rules.maxLength);
            break;
          case 'rangeOverflow':
            length = numberFormat(rules.max);
            break;
          default:
            length = false;
        }
        var validationMessage = t("".concat(errorPrefix, ".").concat(validityReq), {
          length: length
        });
        return [false, validationMessage];
      }
    }
  }
  return [true, null];
};
var checkCustomValidation = function checkCustomValidation(t, validationName, value, customValidationFunction) {
  var errorPrefix = validationName ? "errors.".concat(validationName) : "errors";
  var validationResult = customValidationFunction(value);
  if (_typeof(validationResult) === 'object' && 'isValid' in validationResult && !validationResult.isValid) {
    return [false, (validationResult === null || validationResult === void 0 ? void 0 : validationResult.message) || t("".concat(errorPrefix, ".badInput"))];
  }
  if (!validationResult) {
    return [false, t("".concat(errorPrefix, ".badInput"))];
  }
  return [true, null];
};
/** Keep track of field's validation status, returning current validity and message.
    Publish field validity status to global context if fieldKey is given. */
export var useValidation = function useValidation(_ref) {
  var value = _ref.value,
    inputRef = _ref.inputRef,
    fieldKey = _ref.fieldKey,
    validationName = _ref.validationName,
    rules = _ref.rules,
    customValidationFunction = _ref.customValidationFunction;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isTouched = _useState2[0],
    setTouched = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    isValid = _useState4[0],
    setValid = _useState4[1];
  var _useState5 = useState(null),
    _useState6 = _slicedToArray(_useState5, 2),
    validationMessage = _useState6[0],
    setValidationMessage = _useState6[1];
  var _useTranslation = useTranslation(),
    _useTranslation2 = _slicedToArray(_useTranslation, 1),
    t = _useTranslation2[0];
  useEffect(function () {
    var _inputRef$current;
    inputRef === null || inputRef === void 0 ? void 0 : (_inputRef$current = inputRef.current) === null || _inputRef$current === void 0 ? void 0 : _inputRef$current.setCustomValidity('');
    var _checkHtml5Validation = checkHtml5Validation(t, validationName, inputRef, rules),
      _checkHtml5Validation2 = _slicedToArray(_checkHtml5Validation, 2),
      valid = _checkHtml5Validation2[0],
      message = _checkHtml5Validation2[1];
    setValid(valid);
    setValidationMessage(message);
    if (valid && customValidationFunction) {
      var _inputRef$current2;
      var _checkCustomValidatio = checkCustomValidation(t, validationName, value, customValidationFunction),
        _checkCustomValidatio2 = _slicedToArray(_checkCustomValidatio, 2),
        _valid = _checkCustomValidatio2[0],
        _message = _checkCustomValidatio2[1];
      inputRef === null || inputRef === void 0 ? void 0 : (_inputRef$current2 = inputRef.current) === null || _inputRef$current2 === void 0 ? void 0 : _inputRef$current2.setCustomValidity(_message || '');
      setValid(_valid);
      setValidationMessage(_message);
    }
  }, [fieldKey, value, inputRef, rules, customValidationFunction]);
  var onSetTouched = function onSetTouched(e) {
    if (e) {
      e.preventDefault();
    }
    setTouched(true);
  };
  var onSetUntouched = function onSetUntouched(e) {
    if (e) {
      e.preventDefault();
    }
    setTouched(false);
  };
  useEffect(function () {
    if (inputRef && inputRef.current) {
      var _inputRef$current3, _inputRef$current4;
      inputRef === null || inputRef === void 0 ? void 0 : (_inputRef$current3 = inputRef.current) === null || _inputRef$current3 === void 0 ? void 0 : _inputRef$current3.addEventListener('revalidateInput', onSetTouched);
      inputRef === null || inputRef === void 0 ? void 0 : (_inputRef$current4 = inputRef.current) === null || _inputRef$current4 === void 0 ? void 0 : _inputRef$current4.addEventListener('resetValidation', onSetUntouched);
    }
    return function () {
      if (inputRef && inputRef.current) {
        var _inputRef$current5, _inputRef$current6;
        inputRef === null || inputRef === void 0 ? void 0 : (_inputRef$current5 = inputRef.current) === null || _inputRef$current5 === void 0 ? void 0 : _inputRef$current5.removeEventListener('revalidateInput', onSetTouched);
        inputRef === null || inputRef === void 0 ? void 0 : (_inputRef$current6 = inputRef.current) === null || _inputRef$current6 === void 0 ? void 0 : _inputRef$current6.addEventListener('resetValidation', onSetUntouched);
      }
    };
  }, [inputRef.current]);
  return [isValid, isTouched, setTouched, validationMessage];
};
export var FORCE_RE_VALIDATE_FORM_EVENT_NAME = 'forceReValidateForm';

/**
 * Subscribe to forced validation events triggered for this formRef.
 * When event is received in formRef, loop through fieldRefs and trigger validation on each.
 */
export var useForceReValidateFormEvents = function useForceReValidateFormEvents(formRef, fieldRefs) {
  var forceReValidateFields = function forceReValidateFields() {
    var event = createCustomEvent('revalidateInput');
    fieldRefs.forEach(function (ref) {
      var _ref$current;
      ref === null || ref === void 0 ? void 0 : (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.dispatchEvent(event);
    });
  };
  useEffect(function () {
    var _formRef$current;
    (_formRef$current = formRef.current) === null || _formRef$current === void 0 ? void 0 : _formRef$current.addEventListener(FORCE_RE_VALIDATE_FORM_EVENT_NAME, forceReValidateFields);
    return function () {
      var _formRef$current2;
      (_formRef$current2 = formRef.current) === null || _formRef$current2 === void 0 ? void 0 : _formRef$current2.removeEventListener(FORCE_RE_VALIDATE_FORM_EVENT_NAME, forceReValidateFields);
    };
  }, [formRef.current]);
};
export var trackCodeCheck = function trackCodeCheck(trackCode) {
  var regex = /^[0-9a-zA-Z]{16}$/;
  if (regex.test(trackCode) === true) {
    return trackCode;
  }
  return '';
};