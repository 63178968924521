import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import { HTTP_CODES } from '../constants';
export default {
  httpGet: function httpGet(url, init) {
    return fetch(normalizeUrl(url), Object.assign({
      credentials: 'same-origin'
    }, init)).then(handleLogin).then(handleError);
  },
  httpPost: function httpPost(url, body) {
    return httpMethod('POST', url, body);
  },
  httpPut: function httpPut(url, body) {
    return httpMethod('PUT', url, body);
  },
  httpDelete: function httpDelete(url, body) {
    return httpMethod('DELETE', url, body);
  },
  upload: function upload(url, body) {
    var formData = new FormData();
    formData.append('file', body);
    return fetch(normalizeUrl(url), {
      body: formData,
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'X-XSRF-TOKEN': getCsrfToken() || '' // TODO: token name
      }
    }).then(handleLogin).then(handleError);
  }
};
var loginHasBeenstarted = false;
var isLoggedIn = function isLoggedIn(response) {
  if (response.status == 401) {
    if (loginHasBeenstarted) {
      return false;
    }
    loginHasBeenstarted = true;
    var lang = localStorage.getItem('USER_LOCALE');
    var track = sessionStorage.getItem('TRACK_NAME') !== 'undefined' ? "&track=".concat(sessionStorage.getItem('TRACK_NAME')) : '';
    window.location.href = '/relogin' + (lang ? '?lang=' + lang : '') + track;
    return false;
  }
  return true;
};
var handleLogin = function handleLogin(response) {
  if (!isLoggedIn(response)) {
    return Promise.reject('user not logged in');
  }
  return response;
};
var handleError = function handleError(response) {
  return new Promise(function (resolve, reject) {
    return response.status >= HTTP_CODES.OK && response.status < 300 ? resolve(response) : response.status === HTTP_CODES.UNAUTHORIZED ? process.env.NODE_ENV === 'production' // Handle unauthorized
    ? window.location.reload() : reject(new Error('Not logged in')) : response // Handle errors
    .json().then(function (error) {
      return reject(response.status);
    }).catch(function (error) {
      return reject(response.status);
    });
  });
};
var normalizeUrl = function normalizeUrl(url) {
  return url && url.indexOf('/') === 0 ? url.substr(1) : url;
};
var httpMethod = function httpMethod(method, url, body) {
  return fetch(normalizeUrl(url), {
    body: JSON.stringify(body),
    credentials: 'include',
    //was: same-origin
    headers: {
      //      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getCsrfToken() || ''
    },
    method: method
  }).then(handleLogin).then(handleError);
};
var getCsrfToken = function getCsrfToken() {
  var xsrfToken = ('; ' + document.cookie).split('; XSRF-TOKEN=').pop().split(';').shift();
  return xsrfToken;
};
export { HTTP_CODES };