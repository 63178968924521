import { sendPageInfo } from '@postidigital/posti-google-analytics';
export var PageInfoSection;
(function (PageInfoSection) {
  PageInfoSection["RegisterBusinessCustomer"] = "register-business-customer";
  PageInfoSection["ConfirmRegistration"] = "confirm-registration";
  PageInfoSection["PriceList"] = "price-list";
  PageInfoSection["SignContract"] = "sign-contract";
  PageInfoSection["ConfirmEmail"] = "confirm-email";
  PageInfoSection["Failure"] = "failure";
  PageInfoSection["MaintenanceError"] = "maintenance-error";
  PageInfoSection["BusinessCustomerFromDefaultTrack"] = "business-customer-from-default-track";
  PageInfoSection["BusinessCustomerFromEcommerceTrack"] = "business-customer-from-ecommerce-track";
})(PageInfoSection || (PageInfoSection = {}));
export var ActionType;
(function (ActionType) {
  ActionType["PricingInfoModal"] = "pricingInfoModal";
  ActionType["ReturnToContractModal"] = "returnToContractModal";
  ActionType["CloseAndLogOut"] = "closeAndLogOut";
  ActionType["NewOmaPostiB2BUser"] = "newOmaPostiB2BUser";
})(ActionType || (ActionType = {}));
export var containerId = 'GTM-T93CKXS';
var EVENT_SOURCE = 'corporate_bonboarding';
export var sendAnalytics = function sendAnalytics(section, locale) {
  sendPageInfo({
    language: locale,
    solution: EVENT_SOURCE,
    domain: document.location.hostname,
    pagePath: "/corporate_bonboarding_".concat(section),
    pageName: "corporate_bonboarding_".concat(section)
  });
};