export default {
  fatalError: {
    title: 'Yhteys palvelimelle epäonnistui.',
    body: 'Lähettämisen palvelua ei voitu ladata.',
    reload: 'Yritä uudelleen'
  },
  verifyInformationView: {
    notificationText: 'Du håller på att registrera dig som Postis företagskund. Kontrollera att uppgifterna nedan är korrekta.',
    buttonBack: 'Tillbaka',
    accountAlreadyExists: 'Samma e-postadress har redan ett kundkonto hos Posti. Aktivera behörigheten som företagskund genom att klicka på länken som finns i bekräftelsemeddelandet och logga in i tjänsten med koderna till ditt existerande användarkonto.'
  },
  becomeCustomerView: {
    notificationStart: 'På formuläret ber vi endast om de uppgifter som vi behöver för att kunna behandla ärendet. Mer information om ',
    notificationLink: ' behandlingen av personuppgifter',
    notificationEnd: ' hos Posti.',
    companyInformationHeader: 'Företagets uppgifter',
    registrationInfoTooltip: 'På formuläret ber vi endast om de uppgifter som vi behöver för att kunna behandla ärendet. Mer information om behandlingen av personuppgifter hos Posti.',
    companyName: 'Företagets namn',
    businessId: 'FO-nummer',
    websiteUrl: 'Webbutikens URL, om tillämpligt',
    country: 'Land',
    officialCompanyAddress: 'Gatuadress',
    addressTooltip: 'Om företagsadressen har flera rader, använd kommatecken mellan raderna.',
    postalCode: 'Postnummer',
    city: 'Postanstalt',
    tradeRegister: 'Företaget är registrerat i',
    tradeRegisterLink: 'det finska handelsregistret',
    mandatoryInfoText: '*Obligatorisk uppgift',
    addressHeadline: 'Företagets adress',
    addressSubtitle: 'Denna adress används som företagets besöks-, leverans- och faktureringsadress. Du kan ändra uppgifterna senare.',
    mainUserInformationHeader: 'Administratörens uppgifter',
    mainUserInformation: 'När du anger en e-postadress som redan har ett konto i Postis nättjänst, kompletteras det existerande kontot med detta nya företagskonto.',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    emailAddress: 'E-postadress',
    phone: 'Telefonnummer',
    creditCheckInfo: 'Jag bekräftar härmed att:',
    creditCheckFirst: 'jag har rätt att fungera som huvudanvändare för det registrerade företaget i enlighet med användningsvillkoren för Postis avtalskunders ',
    // eslint-disable-next-line
    creditCheckLink: 'elektroniska servicekanaler',
    creditCheckEnd: '.',
    creditCheckSecond: 'en kreditkontroll kan göras för företaget som jag registrerar.',
    buttonNext: 'Fortsätt',
    buttonCancel: 'Avbryt'
  },
  errors: {
    officialNameField: {
      valueMissing: 'Ange företagets namn',
      patternMismatch: 'Namn är ogiltigt'
    },
    businessIdField: {
      valueMissing: 'Ange giltigt FO-nummer',
      patternMismatch: 'Fel FO-nummer. Ge ett finländskt FO-nummer i formatet 1234567-8.',
      badInput: 'FO-nummer hittas inte. Ange giltigt FO-nummer.'
    },
    addressField: {
      valueMissing: 'Ange giltig adress',
      patternMismatch: 'Adress är ogiltigt'
    },
    postalCodeField: {
      valueMissing: 'Ange postnummer',
      patternMismatch: 'Postnummer är ogiltigt'
    },
    cityField: {
      valueMissing: 'Ange postanstalt',
      patternMismatch: 'Postanstalt är ogiltigt'
    },
    firstName: {
      valueMissing: 'Ange förnamn',
      patternMismatch: 'Förnamn är ogiltigt'
    },
    lastName: {
      valueMissing: 'Ange efternamn',
      patternMismatch: 'Efternamn är ogiltigt'
    },
    email: {
      valueMissing: 'Ange e-postadress',
      patternMismatch: 'Fel i e-postadressen. Ange din e-postadress i formatet peppi@mail.com.'
    },
    phoneNumber: {
      valueMissing: 'Telefonnummer måste anges',
      patternMismatch: 'Telefonnummer är ogiltigt'
    },
    websiteUrlField: {
      patternMismatch: 'Webbutikens URL är ogiltigt'
    }
  },
  pricingView: {
    small: 'Småpaket',
    normal: 'Postpaket',
    homeParcelCity: 'Hempaket',
    homeParcelCityDetails: '(Stadsområde)',
    homeParcelOther: 'Hempaket',
    homeParcelOtherDetails: '(Annat område)',
    expressParcel: 'Expresspaket',
    customerReturn: 'Kundreturn',
    dialog: {
      header: 'Regional prissättning av hemleveranser',
      contentHeader: 'Stadsområden',
      contentBody: 'Stadsområdenas postnummer omfattar centrumen i de största städerna i Finland (listan nedan). Cirka 40 % av Finlands befolkning bor i dessa områden.',
      subHeader: 'Övriga områden',
      subBody: 'Med övriga områden avses annat än centrum i stora städer, till exempel förorter och landsbygd.',
      postcodeStart: 'Ladda ner postnummer för ',
      postcodeLink: 'Hempaket',
      postcodeEnd: ' på Hempaketets presentationssida.',
      button: 'Stäng'
    },
    area: {
      helsinki: 'Huvudstadsregionen',
      turku: 'Åboregionen',
      tampere: 'Tammerforsregionen',
      oulu: 'Uleåborg',
      jyväskylä: 'Jyväskylä',
      lahti: 'Lahtis',
      kuopio: 'Kuopio'
    },
    header: 'Skicka paketen förmånligare',
    subtitle: 'Priser',
    service: 'Paket',
    pricesFrom: 'Pris från',
    fuelPart1: 'Till priset på paketen tillkommer ',
    fuelLink: 'bränsletillägg',
    fuelPart2: '. Minsta faktureringsvikt är 1 kg.',
    buttonDownload: 'Ladda prislista',
    payFor: 'Vad består priset av?',
    titleOne: 'Försändelse',
    titleTwo: 'Bränsle',
    titleThree: 'Tilläggstjänster',
    titleFour: 'Fakturering',
    titleFive: 'Försändelse <br/> av en kettlebell',
    titleSix: 'Försändelse <br/> av en kudde',
    titleSeven: 'Mervärdesskatt (moms)',
    serviceOne: 'Försändelsens pris bestäms enligt paketets verkliga vikt eller volymvikt (se exemplet nedan).',
    serviceTwo: 'På priserna tillkommer ett bränsletillägg.',
    serviceThree: 'Priset på en försändelse ändras om du beställer tilläggstjänster för den (till exempel Postförskott).',
    serviceFour: 'Om du skickar mer än 100 € paket per vecka, skickar vi en faktura varje vecka. Mindre än 100 € faktureras varje månad. Om det fakturerade beloppet är mindre än 100 € per månad debiterar vi en 10 € faktureringsavgift. Priset på en pappersfaktura är 5,24 € + moms/faktura.',
    serviceFive: 'Om du skickar en kettlebell i ett 43 x 35 x 18 cm stort paket, är paketets verkliga vikt (16 kg) större än dess volymvikt (0,43 m x 0,35 m x 0,18 m x 250 kg/m3 = 6,77 kg). Då beräknas priset enligt den verkliga vikten 16 kg Postpaket, till exempel (5,00 € + (0,20 €/kg x 16 kg)) x 1,12 = 9,14 € (I priset ingår 12% bränsletillägg men inga andra tillägg eller moms).',
    serviceSix: 'Om du skickar en kudde i ett 43 x 35 x 18 cm stort paket, är paketets verkliga vikt (under 1 kg) mindre än dess volymvikt (0,43 m x 0,35 m x 0,18 m x 250 kg/m3 = 6,77 kg). Då beräknas priset enligt volymvikten 6,77 kg Postpaket, till exempel (5,00€ + (0,20€/kg x 6,77kg)) x 1,12 = 7,11 € (I priset ingår 12% bränsletillägg men inga andra tillägg eller moms).',
    serviceSeven: 'I exempelpriserna ovan ingår inte mervärdesskatt. Vid fakturering tillkommer gällande moms på priserna. Postavgifterna för postförsändelser till Åland, till specialområden utanför EG:s momsområde och till länder utanför EU är momsfria.',
    weightVolume: 'Försändelsens pris: verklig vikt eller volymvikt?',
    weightVolumeSubtitleStart: 'Försändelsens pris bestäms på basis av paketets verkliga vikt eller volymvikt (bredd x längd x höjd x 250 kg/m3 **), beroende på vilkendera av vikterna som är störst. Minimivikten är 100 g. Till exempel:',
    standardStart: '** 250 kg/m',
    standardSup: '3',
    standardEnd: 'är det standardmått för densitet som logistikföretagen använder vid beräkning av volymvikten.',
    buttonCancel: 'Tillbaka',
    buttonNext: 'Fortsätt',
    deliveryZone: '*På Expresspaket tillkommer en tilläggsavgift för utdelningszonen i enlighet med destinationspostnumret: zon 1: 0,5 € / försändelse och zon 2: 1 €/försändelse. Se utdelningszonerna',
    deliveryZoneLink: 'här',
    pricesValid: 'Priserna gäller tillsvidare',
    otherServicePrices: 'Listade inrikes tjänster erbjuds av Posti Oy och internationella tjänster som listas erbjuds av antingen Posti Oy eller Posti Distribution Oy. Andra tjänster som erbjuds av Posti Ab eller Posti Distribution Ab debiteras enligt prislistorna.',
    invoicingDetails: 'Faktureringsunderlag för befintliga faktura kunder kommer inte att ändras',
    internationalParcels: 'Internationella godstransporttjänster',
    seePricesListBelow: 'Se prislista nedan'
  },
  //Includes only the translations that differ from the default pricing view
  ecommercePricingView: {
    pricesValid: '<strong>Prisnivå 1</strong> (gäller tills vidare) mindre än 500 paket under kalenderåret',
    pricesValidTwo: '<strong>Prisnivå 2</strong> (gäller tills vidare) mer än 500 paket under kalenderåret',
    cardKettlebell: 'Om du skickar en kettlebell i ett <strong>43 x 35 x 18 cm stort paket</strong>, är paketets verkliga vikt (16 kg) större än dess volymvikt (0,43 m x 0,35 m x 0,18 m x 250 kg/m3 = 6,77 kg). Då beräknas priset <strong>enligt den verkliga vikten 16 kg postpaket</strong>, till exempel 5,90 € + (0,30 €/kg x 16 kg) = <strong>10,70 €</strong> (inkluderar inte andra tillägg eller moms).',
    cardPillow: 'Om du skickar en kudde i ett <strong>43 x 35 x 18 cm stort paket</strong>, är paketets verkliga vikt (under 1 kg) mindre än dess volymvikt (0,43 m x 0,35 m x 0,18 m x 250 kg/m3 = 6,77 kg). Då beräknas priset <strong>enligt volymvikten 6,77 kg postpaket</strong>, till exempel 5,90€ + (0,30€/kg x 6,77kg) = <strong>7,93 €</strong> (inkluderar inte andra tillägg eller moms).',
    serviceHeader: 'Nyttig information om prissättningen',
    titleNoFees: 'Inga extra avgifter',
    bodyNoFees: 'Ingen faktureringstillägg eller bränsletillägg i Starterpack-produkter.',
    serviceFour: 'Om du skickar mer än 100 € paket per vecka, skickar vi en faktura varje vecka. Mindre än 100 € faktureras varje månad. Priset på en pappersfaktura är 5,24 € + moms/faktura.',
    info: '* Försändelsens pris bestäms på basis av paketets verkliga vikt eller volymvikt (bredd x längd x höjd x 250 kg/m3 *), beroende på vilkendera av vikterna som är störst. Minimivikten är 100 g och maximalvikten är 25 Kg.',
    infoLink: 'Läs mer om fraktvikten.',
    infoSecond: '** En leveranszonsavgift tillkommer för Expresspaket i enlighet med paketets destinationspostnummer: 0,6 €/st för zon 1 och 1,2 €/st för zon 2.',
    infoSecondLink: 'Se leveranszoner.',
    readMore: 'Läs mer om Prisnivå 2 i prisbilagan',
    infoThird: 'Tjänster som anges ovan erbjuds av Posti Ab. Andra tjänster som erbjuds av Posti Ab eller Posti Distribution Ab debiteras enligt',
    infoThirdLink: 'prislistorna',
    normalParcelSmall: 'Postpaket (max. 40 x 32 x 26 cm)',
    normalParcelLarge: 'Postpaket (över 40 x 32 x 26 cm)*',
    homeParcelCity: 'Hempaket (max. 40 x 32 x 26 cm)',
    homeParcelOther: 'Hempaket (över 40 x 32 x 26 cm)*',
    deliveryHome: 'Hem',
    deliveryPickup: 'Uthämtningsställe',
    deliveryBusiness: 'Företag',
    expressParcel: '**Expresspaket (max. 40 x 32 x 26 cm)',
    expressParcelLarge: '**Expresspaket (over 40 x 32 x 26 cm)*',
    returnSmall: 'Retur (max. 40 x 32 x 26 cm)',
    returnLarge: 'Retur (över 40 x 32 x 26 cm)*',
    modalHeader: 'Hur beräknas <br/> fraktvikten?',
    modalInfo: 'Fraktvikt betyder att försändelsens pris bestäms utifrån paketets verkliga vikt eller volymvikt (bredd x längd x höjd x 250 kg/m3 *), beroende på vilkendera av vikterna som är störst. Minimivikten är 100 g och maximivikten 25 kg. Fraktvikten påverkar priserna på Hempaket samt på Postpaket och returer som är större än 40 x 32 x 26 cm.',
    tableHeaders: {
      shipmentPrices: 'Försändelsernas priser',
      item: '€ / Leverans',
      kg: '€ / kg*',
      delivery: 'Leverans',
      customerReturn: 'Retur eller ej uthämtad försändelse'
    },
    campaignTitle: 'Priser efter sommarkampanjen för små webbutiker***',
    campaignInfo: '***Priskampanjen är i kraft 16.5.2022-31.8.2022. Småpaket 4,00 €. Postpaket 5,50 € och Retur 6,50 €. (Priset är fast när storleken på paketet är högst 40 x 32 x 26 cm. I annat fall gäller kampanjpriset för den fasta delen av Postpaketet och Returen.)',
    currentPrices: 'Löpande priser',
    upcomingPrices: 'Vi uppdaterar prislistan för startpaketet 26.2.2023. Ladda ner den nya prislistan nedan.'
  },
  countries: {
    FI: 'Finland',
    SE: 'Sverige',
    PL: 'Polen',
    EE: 'Estland',
    GB: 'England',
    CZ: 'Tjeckien',
    HU: 'Ungern',
    DK: 'Denmark',
    DE: 'Tyskland',
    ES: 'Spanien',
    BE: 'Belgien',
    NO: 'Norge',
    LT: 'Litauen',
    CH: 'Schweiz',
    IE: 'Irland',
    NL: 'Nederländerna',
    SK: 'Slovakien',
    FR: 'Frankrike',
    RO: 'Rumänien',
    LV: 'Lettland',
    BG: 'Bulgarien',
    PT: 'Portugal',
    GI: 'Gibraltar',
    RU: 'Ryssland',
    AT: 'Österrike',
    IT: 'Italien',
    RS: 'Serbien'
  },
  contractView: {
    buttonBack: 'Tillbaka',
    buttonAccept: 'Underteckna avtalet',
    buttonDownload: 'Ladda som PDF',
    header: 'Läs igenom avtalet innan du undertecknar det.',
    contractHeader: 'Användningsvillkor för Posti Ab:s avtalskunders elektroniska kanaler 1.1.2023',
    subtitle1: '1. Tillämpningsområde',
    subtitle2: '2. Avtalsvillkor',
    subtitle3: '3. Införande av Tjänsten',
    subtitle4: '4. Tjänst',
    subtitle5: '5. Betalningssätt',
    subtitle6: '6. Koder och administratör',
    subtitle7: '7. Asiakkaan vastuu',
    subtitle8: '8. Kundens integrationsgränssnitt i Tjänsten',
    subtitle9: '9. Postis ansvar',
    subtitle10: '10. Ändring och uppsägning av Tjänsten och Användningsvillkoren',
    subtitle11: '11. Användningsvillkorens giltighet',
    subtitle12: '12. Övriga villkor',
    content1: '1.1.  Posti Ab (FO-nummer: 2344200-4), Posti Distribution Ab (FO-nummer: 0109357-9) och företag som tillhör samma koncern (nedan kallad "Posti") förser sina avtalskunder (Kund) med digitala kanaler och tjänster för användning av Postis avtalstjänster samt förvaltning av Kundens egna uppgifter (Tjänst). Dessa användningsvillkor (Användningsvillkor) tillämpas på den Tjänst som används av Kunden.',
    content2: '2.1. På användningen av Tjänsten tillämpas i första hand dessa Användningsvillkor och i andra hand Postis allmänna avtalsvillkor. Dessutom kan Posti separat i Tjänsten ge anvisningar som gäller användningen av den.',
    content22: '2.2. Om eventuella anvisningar som Posti ger i Tjänsten står i strid med Användningsvillkoren, tillämpas i första hand anvisningarna i fråga.',
    content23: '2.3. Postis tjänster tillgängliga genom tjänsten är föremål för bl.a. produktvillkor för tjänsten och Postis allmänna avtalsvillkor för företagskunder, samt andra eventuella dokument som anges i 20 § i de allmänna villkoren.',
    content3: '3.1. Införande av Tjänsten förutsätter att Kunden har avtalat med Posti om en avtalskundrelation som berättigar till att använda Postis avtalstjänster.',
    content32: '3.2. Kunden godkänner Användningsvillkoren för Tjänsten då han eller hon börjar använda Tjänsten.',
    content4: '4.1. Kunden kan få rapporter i Tjänsten om Postis tjänster som Kunden använder samt beställa eller använda tjänster som erbjuds via Tjänsten.',
    content42: '4.2. Användning av tjänsten sker alltid på Kundens ansvar. Posti ansvarar inte för att Tjänsten är tillgänglig för Kunden utan avbrott och fel.',
    content43: '4.3. En del av tjänsterna kan förutsätta identifiering av Kunden eller den som agerar för Kundens räkning.',
    content44: '4.4. Kunden får använda Tjänsten endast i sin egen interna verksamhet. Kunden har inte rätt att återsälja eller på annat motsvarande sätt dela Tjänsten.',
    content45: '4.5. Användningen av Tjänsten kan göra det möjligt att nyttja eller beställa produkter och tjänster även av tredje part ("Annan serviceproducent"). Posti svarar inte för produkter, tjänster, verksamhet eller uppgifter som har lämnats av Andra serviceproducenter. Avtal om produkter och tjänster av Annan serviceproducent ingås mellan Kunden och den andra serviceproducenten, och Posti är inte part i ett sådant avtal och svarar heller inte för förpliktelser som hänför sig till det.',
    content5: '5.1. Om inget har avtalats med Kunden om fakturering (självbetjäningskund), ska tjänsterna betalas i Tjänsten med vid var tid tillgängliga betalningssätt vid beställningen. Posti har rätt att ta ut kundens avgifter eller ställa en reservering av medel på det betalningssätt som kunden använder.',
    content52: '5.2. Ärendekontot är ett betalningssätt som Posti Ab erbjuder sina självbetjäningskunder. Via ärendekontot kan Kunden betala för de tjänster som Posti erbjuder i Tjänsten.',
    content53: '5.3. Självbetjäningskunder ansvarar för att det alltid på Ärendekontot eller ett annat betalningssätt som kunden använder och som godkänts av Posti finns tillräckligt med medel för att betala kundens inköp. Ärendekontots saldo kan inte övertrasseras.',
    content54: '5.4. När Kunden överför medel från sitt bankkonto på Ärendekontot ska vid kontoöverföringen användas det referensnummer som Posti angett, så att medlen kan inriktas till Kundens Ärendekonto. De medel som Kunden överfört med kontoöverföring via sin egen bank finns i regel tillgängliga på Ärendekontot följande bankdag efter kontoöverföringen. Tiden som går åt för kontoöverföringen beror på de avtal som ingåtts mellan bankerna. Kunden är medveten om att det kan ta flera dagar innan kontoöverföringen registreras på Ärendekontot. När Kunden överför medel från sitt bankkonto till Ärendekontot med Ärendekontots knapp för nätbetalning, är medlen tillgängliga för Kunden på Ärendekontot omedelbart efter en lyckad nätbetalning.',
    content55: '5.5. Posti betalar inte ränta på medlen på Ärendekontot. Kundens medel har separerats från Postis medel i bokföringen.',
    content56: '5.6. Kunden kan när som helst ta ut de tillgängliga medel som finns på Ärendekontot. Medlen betalas på det bankkonto som Kunden angett som Ärendekontots motkonto. Förutom de medel som är tillgängliga för Kunden kan Ärendekontot innehålla medel som är reserverade för Kundens tjänster eller sådana utbetalningar som Posti beviljat i en kampanj eller betalat som kompensation. Dessa utbetalningar kan användas endast till att betala Postis tjänster i Nätverket-tjänsten och Kunden kan inte ta ut dem från Ärendekontot.',
    content6: '6.1. Kunden ska uppge åtminstone en administratör för Posti. Posti skickar administratörens koder till Kunden när Posti har godkänt registreringen av Kunden.',
    content62: '6.2. Kundens administratör skapar och administrerar koderna för Kundens övriga användare och fungerar som kontaktperson mellan Posti och Kunden i ärenden som gäller Tjänsten. Kunden ansvarar för att serviceportalen alltid innehåller uppdaterade uppgifter om Kundens administratör.',
    content63: '6.3. Administratören har rätt att använda alla delar och material i Tjänsten som Kunden har behörighet till. Andra än Administratörens koder kan ha behörighetsbegränsningar.',
    content64: '6.4. Kunden ska förvara alla sina koder omsorgsfullt och så att de inte hamnar orättmätigt hos utomstående. Kunden ska själv avstänga behörigheten för koden om Kundens kod har förkommit eller hamnat i utomståendes besittning.',
    content65: '6.5. Koderna är personliga och en person kan använda Tjänsten med samma kod som representant för olika kunder. Kunden ansvarar för att Kundens representationsrätt som fogats till koderna endast används av sådana personer som har rätt att företräda Kunden i Tjänsten på det sätt som representationsrätten möjliggör. Kunden ska själv avsluta den representationsrätt som fogats till koderna.',
    content66: '6.6. Användningen av Kundens koder motsvarar Kundens underskrift.',
    content67: '6.7. Posti har rätt, men inte skyldighet, att avlägsna en Kunds koder som inte har använts under åtminstone ett år.',
    content7: '7.1. Kunden ansvarar för administrationen av koder samt behörigheter som anknyter till dem. Kundens Administratör administrerar Kundens koder samt omfattningen av till dem hörande behörigheter.',
    content72: '7.2. Kundens Administratör ansvarar för användarstödet till Kundens övriga användare.',
    content73: '7.3. Kunden ansvarar för all verksamhet som genomförs i Tjänsten med sina koder.',
    content74: '7.4. Kunden ansvarar för att den hårdvara och annan utrustning, programvara och datakommunikationsförbindelser som Kunden använder motsvarar de krav som Posti angivit och är tillräckligt skyddade bland annat genom att använda uppdaterade och effektiva antivirusprogram och andra motsvarande medel för att skydda dem. Kunden ansvarar själv för kostnaderna och funktionen av de apparater, programvaror och informations- och datakommunikationsförbindelser som förutsätts för användningen av Tjänsten. Vid användningen av Tjänsten är Kunden skyldig att iaktta Postis gällande anvisningar om användning och säkerhet samt andra anvisningar.',
    content75: '7.5. Kunden ska lämna till Posti de uppgifter som behövs för Tjänsten. Kunden skall utan dröjsmål meddela om ändringar i uppgifterna. Kunden ansvarar för riktigheten av de uppgifter som Kunden anger för Tjänsten. Posti är inte skyldig att kontrollera eller korrigera de uppgifter som Kunden anger för Tjänsten.',
    content76: '7.6. Kunden ansvarar för de uppgifter, filer, dokument och annat material (Material) som Kunden förvarar, hanterar eller förmedlar i Tjänsten.',
    content77: '7.7. Kunden ansvarar för att användningen av Tjänsten inte strider mot lagar och god sed. Posti övervakar inte Material som produceras, skickas, förmedlas eller på ett annat sätt hanteras i Tjänsten och Posti ansvarar inte för ett lagstridigt eller kränkande innehåll i sådant material. Kunden ansvarar för att denne inte med hjälp av Tjänsten producerar, skickar, förvarar eller på något annat sätt behandlar material som kränker upphovsrätten eller andra rättigheter, god sed, lagar eller myndighetsbestämmelser. Sådan kränkning kan medföra skyldighet att ersätta den vållade skadan. Kunden ansvarar för innehållet och presentationsformen av det Material som Kunden skickar eller på annat sätt hanterar med hjälp av eller via Tjänsten.',
    content78: '7.8. Kunden ska innan Material matas in i Tjänsten säkerställa att Materialet inte strider mot avtalsvillkoren som gäller Tjänsten eller mot Postis anvisningar samt att Materialet inte innehåller datavirus eller andra sådana egenskaper som kan orsaka skada. Kunden ansvarar för att denne inte matar in i Tjänsten Material som innehåller virus eller andra skadliga egenskaper samt för att inte sprida eller på ett annat sätt som orsakar skada hantera sådant Material.',
    content79: '7.9. När Kunden hanterar kunduppgifter eller andra personuppgifter i Tjänsten, ansvarar Kunden i egenskapen av registeransvarig för att lagstiftningen som gäller hantering av personuppgifter följs. Kunden ska försäkra sig om sin rätt att hantera personuppgifter i fråga samt i övrigt också följa vid hanteringen av personuppgifter de förpliktelser som stadgas i lagen eller förutsätts av myndigheter.',
    content8: '8.1. Posti kan avtala med Kunden om integrationsgränssnitt för Tjänsten. Till skillnad från det som sagts tidigare kan Kunden inte själv administrera integrationskoder.',
    content9: '9.1. Posti ansvarar för att Tjänsten genomförs enligt god databehandlingssed.',
    content92: '9.2. Posti upprätthåller Tjänsten så att den i regel står till förfogande 24 h om dygnet, alla dagar i veckan. Posti kan tillfälligt avbryta Tjänsten t.ex. för ändrings- och reparationsarbeten eller med anledning av arbete som underhåll, service eller reparationer medför.',
    content93: '9.3. Posti är skadeståndsskyldig för direkta skador som hör till Postis ansvarskrets och som åsamkats Kunden på grund av att Posti vållat det samt för skador som Kunden påvisar enligt Postis allmänna avtalsvillkor.',
    content94: '9.4. Trots det ovan nämnda ansvarar Posti inte för skador som beror på att Kundens Material har blivit förstört, förkommit eller ändrats.',
    content10: '10.1. Kunden är medveten om att Posti ständigt förbättrar Tjänsten, och därför kan Tjänsten och dess innehåll ändras när som helst. Om ändring av Användningsvillkoren meddelas i Tjänsten.',
    content102: '10.2. Posti har rätt att sluta producera Tjänsten antingen delvis eller helt. Om en sådan ändring meddelas separat och i god tid i Tjänsten.',
    content103: '10.3. Posti har, om det finns skäliga grunder, till exempel på grund av Kundens agerande som strider mot Användningsvillkoren eller ett annat avtalsbrott, rätt att spärra Kundens behörighet utan att meddela om detta på förhand.',
    content104: '10.4. Posti har rätt att utan att höra Kunden avbryta Tjänsten och avlägsna Material som påstås kränka en annans rättighet eller som strider mot lagen, myndighetsanvisningar eller god sed.',
    content11: '11.1. Användningsvillkor träder i kraft 1.1.2023 och gäller tills vidare.',
    content112: '11.2. Posti kan säga upp Användningsvillkoren genom att meddela om detta 30 dagar före uppsägningen i Tjänsten. Kundens nyttjanderätt upphör då Kundens avtalskundrelation upphör.',
    content12: '12.1. Om Kundens Material strider mot Användningsvillkoren eller ett annat avtal mellan Kunden och Posti, är Posti inte skyldig att genomföra Tjänsten eller tjänster enligt de nämnda avtalen. Om det framgår att det Material som Kunden hanterar med hjälp av Tjänsten har ett kränkande innehåll, kränker en annans upphovsrätt eller andra rättigheter, strider mot lagen eller Användningsvillkoren eller villkoren i Kundens övriga avtal eller kan orsaka skada, har Posti rätt att avlägsna materialet i fråga utan att höra Kunden.',
    content122: '12.2. Posti eller dess olika licensgivare innehar alla rättigheter (äganderätt, upphovsrätt och övriga immateriella rättigheter) till Tjänsterna och det relaterade material som Posti tillhandahåller. Rättigheterna till produkter och tjänster av Andra serviceproducenter samt det material som hänför sig till dessa innehas av serviceproducenterna eller deras licensgivare. Kunden har inga immateriella rättigheter till Tjänsten eller därtill hörande programvara eller material eller motsvarande.',
    content123: '12.3.Om Tjänsten enligt detta avtal behandlar personuppgifter som ägs av Kunden utanför EU, till exempel av datasystemskäl, kan Posti överföra personuppgifter med hjälp av lagstiftningen för att utföra Tjänsten.',
    bottomPart1: 'Posten tillhandahåller en elektronisk beställningskanal för användning av Postis avtalstjänster och förvaltning av dina egna uppgifter (”Tjänsten”). För att kunna använda Tjänsten ska ditt företag vara Postis avtalskund och ha ärendekoder. På Tjänsten tillämpas användningsvillkoren för Posti Ab:s Elektroniska kanaler för Avtalskunder. Du godkänner ovan nämnda användningsvillkor och de avtal om behandling av personuppgifter',
    bottomPart1_5: 'som bifogats användningsvillkoren när du börjar använda Tjänsterna.',
    bottomPart2: 'På Postis avtalstjänster som är tillgängliga i tjänsten tillämpas de produktvillkor som gäller vid tidpunkten i fråga, Postis allmänna avtalsvillkor och prislistor. Du godkänner de villkor som tillämpas på Postis tjänst när du beställer tjänsten. Läs omsorgsfullt igenom ',
    bottomPart3: 'avtals- och produktvillkoren',
    bottomPart4: 'och ',
    bottomPart5: 'prislistorna',
    bottomPart6: ' innan tjänsterna tas i bruk. Alla Postis tjänster är inte tillgängliga utan ett separat kundnummer.',
    creditLimit: 'Krediteringsgränsen för fakturering är 2000 euro.',
    enterName: 'Underteckna avtalet genom att ange administratörens hela namn:',
    errorDefault: 'Skriv administratörens hela namn.',
    errorSecondary: 'Fel i namnet.',
    dialogHeader: 'Underteckna avtalet',
    buttonSign: 'Underteckna',
    buttonCancel: 'Avbryt',
    fullName: 'Hela namnet',
    logoutDialogHeader: 'Är du säker på att du vill avvisa avtalet?',
    buttonLeaveAndLogout: 'Stäng och logga ut',
    buttonReturnToContract: 'Gå tillbaka till avtalet',
    logoutDialogText: 'Om du inte slutför din registrering nu, försvinner alla dina uppgifter. Du kan registrera dig som kund senare, men du måste mata in uppgifterna på nytt.'
  },
  emailVerificationView: {
    logOutButton: 'Logga ut',
    header: 'Nästan klar! Bekräfta din e-postadress',
    subtitle: 'Vi skickade ett bekräftelsemeddelande till adressen:',
    bodyText: 'Slutför skapandet av företagskontot genom att klicka på länken i bekräftelsemeddelandet. Länken gäller i 7 dagar. Det kan dröja några minuter innan meddelandet kommer.',
    requestText: 'Kolla skräppostmappen. Om du inte ser meddelandet, ',
    sendLink: 'beställ ett nytt bekräftelsemeddelande.',
    customerServiceText: 'Fick du ingen länk? Gav du fel e-postadress av misstag?',
    customerServiceLink: 'Kontakta kundtjänsten.',
    cashCustomerNotification1: 'Det verkar som att du redan har ett befintligt företagskonto hos Posti! Vi uppdaterar nu dina gamla kunduppgifter, avtalsvillkor och priser.',
    cashCustomerNotification2: 'Du kommer att få en bekräftelselänk i din e-post inom kort. När du har klickat på länken kan du logga in på Oma Posti Pro och börja skapa försändelser!',
    invoicingCustomerNotification1: 'Vi har noterat att ditt företag redan är Posti-kund! Vi har nu skapat ett nytt logistikavtal för dig och slagit samman det med ditt befintliga konto. Du kan se samt redigera ditt företags faktureringsinformation i OmaPosti Pro.',
    invoicingCustomerNotification2: 'Bekräfta din e-postadress så kan du logga in på OmaPosti Pro och börja skicka direkt!',
    invoicingCustomerNotification3: 'Vi kan komma att kontakta dig för att konsolidera ditt nuvarande avtal och det nya avtalet.'
  },
  errorViewContractExists: {
    header: 'Avtalet finns redan',
    subheader: '<0>Företaget {{companyName}}, {{businessId}} har redan ett logistikavtal.</0>',
    howToProceed: 'Hur går jag vidare?',
    newAccountHeader: 'Jag vill skapa ett nytt användarkonto',
    newAccountBody: '<0>IFör att skapa ett nytt användarkonto måste du vara i kontakt med den person på ditt företag som har användarhanteringsbehörighet, så att hen kan bjuda in dig från</0> <1>användarhanteringen i OmaPosti Pro</1>',
    accessRightsHeader: 'Jag behöver åtkomsträttigheter för att kunna sända/skapa försändelser',
    accessRightsBody: '<0>Logga in på</0> <1>Oma Posti Pro</1> <0>och gå till sändningssektionen. Om du inte har tillgång till att sända kommer det att finnas instruktioner om hur man skaffar den.</0>',
    didntKnowHeader: 'Jag visste inte att vi redan hade ett avtal',
    didntKnowBody: '<0>Inga bekymmer, ingen skada skedd. Det befintliga avtalet är opåverkat och används fortfarande. Du kan fortsätta som vanligt.</0><br/><br/><0>Om du inte kan se paketprodukter i sändningsflödet saknar du sannolikt åtkomsträttigheter. För att få åtkomst måste du kontakta den person på ditt företag som ansvarar för användarhantering i OmaPosti Pro.</0>',
    didntKnowLink: '<0>Läs mer om användarhantering i vår kundserviceportal</0>',
    negotiateHeader: 'Jag vill omförhandla det aktuella avtalet',
    negotiateLink: '<0>Fyll i denna blankett för att kontakta säljavdelningen</0>',
    talkHeader: 'Jag vill prata med någon',
    talkLink: '<0>Fyll i denna blankett för att kontakta säljavdelningen</0>'
  },
  errorView: {
    header: 'Något gick fel vid skapandet av kontot',
    body: 'Tyvärr lyckades du inte skapa ett företagskonto.',
    text: 'Fyll i ',
    link: 'blanketten',
    text2: ' så kan vi skapa ett konto åt ditt företag.',
    button: 'Gå tillbaka till Postis förstasida'
  },
  errorViewNotAcceptable: {
    header: 'Det gick inte att skapa ett konto!',
    body: '<0>Det var tyvärr inte möjligt att skapa ett företagskonto på grund av kreditupplysningskontrollen. Du kan använda våra tjänster för sändning utan avtal</0> <1>här</1>.'
  },
  errorViewConflict: {
    header: 'Det gick inte att skapa ett konto!',
    body: '<0>Det var tyvärr inte möjligt att skapa ett företagskonto eftersom företaget redan har ett kundkonto hos Posti. Om du vill börja använda nya tjänster kan du lämna en kontaktbegäran med</0> <1>formuläret</1>.'
  },
  footer: {
    help: 'Hur kan vi hjälpa dig?',
    support: 'Kontakta kundtjänsten'
  },
  header: {
    language: 'Svenska',
    logOut: 'Logga ut'
  },
  becomeCustomerWizard: {
    0: 'Registrera dig som företagskund',
    1: 'Bekräfta uppgifterna om registreringen',
    2: 'Prislista för företagskunder',
    3: 'Läs och underteckna avtalet',
    4: 'Bekräfta e-postadressen',
    5: 'Ready',
    6: 'Thank you'
  },
  maintenanceView: {
    header: 'För tillfället kan du inte registrera dig',
    body: 'Vi utför underhållsarbeten på vår webbplats, så det går inte för tillfället att registrera nya företagskunder. Försök på nytt om en stund.',
    button: 'Gå tillbaka till Postis förstasida'
  },
  cash: {
    fourForty: '4,40 €',
    fourFifty: '4,50 €',
    fiveNinety: '5,90 €',
    fiveSeventy: '5,70 €',
    thirtyCents: '0,30 €',
    eightFifty: '8,50 €',
    eightNinety: '8,90 €',
    tenFifty: '10,50 €',
    tenNinety: '10,90 €',
    sixNinety: '6,90 €',
    sixSeventy: '6,70 €'
  }
};