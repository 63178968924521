export var smallHref = {
  fi: 'https://www.posti.fi/fi/yrityksille/laheta-ja-osta/lahetykset-kotimaahan/paketit/pikkupaketti',
  sv: 'https://www.posti.fi/sv/for-foretag/skicka-och-kop/inrikes-forsandelser/paketer/smapaket',
  en: 'https://www.posti.fi/en/for-businesses/send-and-buy/domestic-shipments/parcels/smallparcel'
};
export var fuelSurchargeHref = {
  fi: 'https://www.posti.fi/fi/asiakastuki/lahettaminen/hinnat-ja-laskurit/yritysasiakkaat-polttoainelisa',
  sv: 'https://www.posti.fi/sv/kundsupport/sandning/priser-och-raknare/bransletillagg',
  en: 'https://www.posti.fi/en/customer-support/sending/prices-and-calculators/fuel-surcharge'
};
export var normalHref = {
  fi: 'https://www.posti.fi/fi/yrityksille/laheta-ja-osta/lahetykset-kotimaahan/paketit/postipaketti',
  sv: 'https://www.posti.fi/sv/for-foretag/skicka-och-kop/inrikes-forsandelser/paketer/postpaket',
  en: 'https://www.posti.fi/en/for-businesses/send-and-buy/domestic-shipments/parcels/postal-parcel'
};
export var homeHref = {
  fi: 'https://www.posti.fi/fi/yrityksille/paketit-ja-logistiikka/kaikki-palvelut/kotipaketti',
  sv: 'https://www.posti.fi/sv/for-foretag/paket-och-logistik/alla-tjanster/hempaket',
  en: 'https://www.posti.fi/en/for-businesses/parcels-and-logistics/all-services/home-parcel'
};
export var expressHref = {
  fi: 'https://www.posti.fi/fi/yrityksille/laheta-ja-osta/lahetykset-kotimaahan/paketit/express-paketti',
  sv: 'https://www.posti.fi/sv/for-foretag/skicka-och-kop/inrikes-forsandelser/paketer/expresspaket',
  en: 'https://www.posti.fi/en/for-businesses/send-and-buy/domestic-shipments/parcels/express-parcel'
};
export var homeParcelHref = {
  fi: 'https://www.posti.fi/fi/yrityksille/paketit-ja-logistiikka/kaikki-palvelut/kotipaketti',
  sv: 'https://www.posti.fi/sv/for-foretag/paket-och-logistik/alla-tjanster/hempaket',
  en: 'https://www.posti.fi/en/for-businesses/parcels-and-logistics/all-services/home-parcel'
};